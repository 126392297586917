import { IconProps } from "@/common/contracts/icon.contracts";

const Austria = ({ className }: IconProps) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3139_24143)">
      <rect y="4" width="16" height="4" fill="#F5F8FB" />
      <rect y="8" width="16" height="4" fill="#DC251C" />
      <rect width="16" height="4" fill="#DC251C" />
    </g>
    <defs>
      <clipPath id="clip0_3139_24143">
        <rect width="16" height="12" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Austria;
