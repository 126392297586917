import { IconProps } from "@/common/contracts/icon.contracts";

const France = ({ className }: IconProps) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3139_23923)">
      <rect width="16" height="12" fill="#F5F8FB" />
      <rect width="5" height="12" fill="#41479B" />
      <rect x="11" width="5" height="12" fill="#E51D1D" />
    </g>
    <defs>
      <clipPath id="clip0_3139_23923">
        <rect width="16" height="12" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default France;
