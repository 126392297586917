import { IconProps } from "@/common/contracts/icon.contracts";

import GermanyFlag from "@whoppah/icons/flags/Germany";
import NetherlandsFlag from "@whoppah/icons/flags/Netherlands";
import BelgiumFlag from "@whoppah/icons/flags/Belgium";
import FranceFlag from "@whoppah/icons/flags/France";
import ItalyFlag from "@whoppah/icons/flags/Italy";
import AustriaFlag from "@whoppah/icons/flags/Austria";
import SwitzerlandFlag from "@whoppah/icons/flags/Switzerland";
import LuxembourgFlag from "@whoppah/icons/flags/Luxembourg";
import GreatBritainFlag from "@whoppah/icons/flags/GreatBritain";

/**
 * These are the countries which are supported in the website. Please use this
 * instead of keys of {@link COUNTRY_MAP} to provide better readability.
 */
export const SUPPORTED_COUNTRIES = [
  "NL",
  "BE",
  "DE",
  "FR",
  "IT",
  "AT",
  "CH",
  "LU",
];

export const COUNTRY_CODES = SUPPORTED_COUNTRIES.map(key =>
  key.toLocaleLowerCase()
);

export type COUNTRY_ID =
  | "NL"
  | "BE"
  | "DE"
  | "GB"
  | "FR"
  | "IT"
  | "AT"
  | "CH"
  | "LU";

/**
 *  This is the map of all country related data. This map can contain data for
 *  more info than {@link SUPPORTED_COUNTRIES}.
 */
export const COUNTRY_MAP: Record<
  COUNTRY_ID,
  {
    name: string;
    callingCode: string;
    flag: (props: IconProps) => JSX.Element;
  }
> = {
  NL: {
    name: "common_netherlands",
    callingCode: "+31",
    flag: NetherlandsFlag,
  },
  BE: { name: "common_belgium", callingCode: "+32", flag: BelgiumFlag },
  GB: {
    name: "common_united_kingdom",
    callingCode: "+44",
    flag: GreatBritainFlag,
  },
  DE: { name: "common_germany", callingCode: "+49", flag: GermanyFlag },
  FR: { name: "common_france", callingCode: "+33", flag: FranceFlag },
  IT: { name: "common_italy", callingCode: "+39", flag: ItalyFlag },
  AT: { name: "common_austria", callingCode: "+43", flag: AustriaFlag },
  CH: { name: "common_switzerland", callingCode: "+41", flag: SwitzerlandFlag },
  LU: { name: "common_luxembourg", callingCode: "+352", flag: LuxembourgFlag },
};
