import { Currency, OrderState, PaymentMethod } from "@/Apollo/schema";
import { authStore } from "@/auth";
import { Device } from "@/common/components/AppDownloadButton";
import {
  AnalyticsLogInStatus,
  AnalyticsMe,
  AnalyticsOrderGroup,
  AnalyticsProduct,
  AnalyticsProvider,
  AnalyticsQuickBidMethod,
} from "@/common/contracts/analytics.contracts";
import {
  getAppType,
  getPageType,
  getUserInfo,
  productToAnalyticsItem,
} from "@/common/utils/analytics.utils";
import { isNil } from "@/common/utils/value.utils";
import { sha256 } from "js-sha256";
import PQueue from "p-queue";
import { v4 as uuid } from "uuid";
import qs from "query-string";
import EventNames = Gtag.EventNames;
import ControlParams = Gtag.ControlParams;
import EventParams = Gtag.EventParams;
import CustomParams = Gtag.CustomParams;
import { ChatMemberRole } from "@/common/contracts/chat.contracts";

declare module "gtag.js" {
  /**
   * Expand the module provided by @types/gtag.js with custom payload format
   * that we are currently using.
   */
  interface Gtag {
    (payload: CustomParams): void; // NOSONAR
  }
}

declare global {
  interface Window {
    dataLayer: (Record<string, unknown> | (() => void))[];
  }
}

/**
 * Helper function that sets the `ecommerce` parameter to `null` in the data layer.
 * This is needed for the enhanced ecommerce tracking. If this parameter is not set to `null`
 * the products will be more than they should be.
 */
const clearEcommerceFromDataLayer = () => {
  window.dataLayer.push({ ecommerce: null });

  // Reset the data layer to the initial state.
  window.dataLayer.push(function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - this is a valid function call.
    this.reset();
  });
};

/**
 * Queue for promises with concurency of 1.
 */
const EVENT_QUEUE = new PQueue({ concurrency: 1 });

/**
 * Helper function that triggers the event with passed parameters and adds `pagetype` parameter
 *
 * @param eventName Name of the event to trigger.
 * @param eventParams Event parameters.
 */
export const gtagEvent = (
  eventName: EventNames | string, // NOSONAR
  eventParams?: ControlParams | EventParams | CustomParams
) => {
  const pageType = getPageType();
  const userId = authStore.getState().userId;

  EVENT_QUEUE.add(() => getUserInfo()).then(up =>
    window.dataLayer.push({
      event: eventName,
      ...eventParams,
      page_type: pageType,
      event_id: uuid(),
      member_id: userId,
      up,
    })
  );
};

const trackProduct = (
  eventName: string,
  product: AnalyticsProduct,
  otherParams?: Record<string, string>
) => {
  const price = product.auction?.buy_now_price?.amount;
  const currency = product.auction?.buy_now_price?.currency;

  gtagEvent(eventName, {
    items: [productToAnalyticsItem(product)],
    currency,
    value: price,
    ...otherParams,
  });
};

const trackMultipleProducts = (
  eventName: string,
  products: AnalyticsProduct[],
  otherParams?: Record<string, string>
) => {
  const currency = products[0]?.auction?.buy_now_price?.currency;

  gtagEvent(eventName, {
    items: products.map(productToAnalyticsItem),
    currency: currency ?? Currency.Eur,
    value: products.reduce((sum, product) => {
      sum += product.auction?.buy_now_price?.amount ?? 0;
      return sum;
    }, 0),
    ...otherParams,
  });
};

const getSignupEventSource = (signupLoginPopup = true) => {
  const pageType = getPageType();
  const { referrer } = qs.parse(window.location.search);

  if (pageType === "home") {
    return signupLoginPopup ? "create_account_home_popup" : "home";
  }

  if (pageType === "pdp") {
    return "pdp";
  }

  if (pageType === "cart") {
    return "checkout";
  }

  if (referrer === "create-ad") {
    return "create-ad";
  }

  if (referrer === "saved-searches") {
    return "saved_searches";
  }

  if (referrer === "bidding") {
    return "bidding";
  }
};

/**
 * Google implementation of event tracking provider.
 */
export const googleAnalyticsProvider: AnalyticsProvider = {
  trackAddToWishlist: (product: AnalyticsProduct) => {
    clearEcommerceFromDataLayer();
    trackProduct("add_to_wishlist", product);
  },
  trackSearch: (query: string, resultsCount: number) => {
    gtagEvent("search", {
      search_term: query,
      search_result: resultsCount,
    });
  },
  trackFormError: (formName: string, fieldName: string, errorText: string) => {
    gtagEvent("form_error", {
      form_name: formName,
      field_name: fieldName,
      error_text: errorText,
    });
  },
  trackClickedWhoppahCourierCheckout: (product: AnalyticsProduct) => {
    trackProduct("clicked_whoppahcourier_checkout", product);
  },
  trackNavigationMenu: (label: string) => {
    gtagEvent("navigation_menu", {
      navigation_item: label,
    });
  },
  trackFooterNavigation: (label: string) => {
    gtagEvent("footer_navigation", {
      navigation_item: label,
    });
  },
  trackSignupLoginSticky: () => {
    gtagEvent("signup_login_sticky_cta");
  },
  trackLogin: (me: AnalyticsMe) => {
    const merchant = me.merchants?.[0];

    gtagEvent("log_in");

    gtag("set", "user_properties", {
      login_status: "logged_in",
      customer_type: merchant?.type,
      cm_buys: merchant?.number_of_buys,
      cm_bids: merchant?.number_of_bids,
      cm_sells: merchant?.number_of_sells,
      cm_ads: merchant?.number_of_ads,
      user_id_custom: merchant?.id,
      cm_favorites: merchant?.number_of_favorites,
      cm_language: me?.locale,
      member_id: me?.id,
    });
  },
  trackLogout: () => {
    gtag("set", "user_properties", {
      login_status: "logged_out",
      customer_type: "",
      cm_buys: "",
      cm_bids: "",
      cm_sells: "",
      cm_ads: "",
      user_id_custom: "",
      cm_favorites: "",
      cm_language: "",
      member_id: "",
    });

    gtagEvent("log_out");
  },
  trackViewItemList: (
    listId: string,
    listName: string,
    products: AnalyticsProduct[]
  ) => {
    clearEcommerceFromDataLayer();
    gtagEvent("view_item_list", {
      item_list_id: listId,
      item_list_name: listName,
      items: products.map(productToAnalyticsItem),
    });
  },
  trackSelectItem: (
    position: number,
    listId: string,
    listName: string,
    product: AnalyticsProduct
  ) => {
    clearEcommerceFromDataLayer();
    gtagEvent("select_item", {
      item_position: position,
      item_list_id: listId,
      item_list_name: listName,
      items: [productToAnalyticsItem(product)],
    });
  },
  trackFilterClickList: (
    filterType: string,
    filterOptions: string | string[] | boolean
  ) => {
    gtagEvent("filter_click_list", {
      filter_type: filterType,
      filter_option: filterOptions,
    });
  },
  trackFilterExpandList: (filterType: string) => {
    gtagEvent("filter_expand_list", {
      filter_type: filterType,
    });
  },
  trackFilterResetList: () => {
    gtagEvent("filter_reset_list");
  },
  trackSaveAlertList: () => {
    gtagEvent("save_alert_list");
  },
  trackSaveAlertSearch: () => {
    gtagEvent("save_alert_search");
  },
  trackSortItemsList: (sortType: string) => {
    gtagEvent("sort_items_list", { sort_type: sortType });
  },
  trackPaginationList: (pageNumber: number) => {
    gtagEvent("pagination_list", { page_number: pageNumber });
  },
  trackNavigationChipsList: (chipCategory: string) => {
    gtagEvent("navigation_chips_list", { chip_category: chipCategory });
  },
  trackViewItem: (product: AnalyticsProduct) => {
    clearEcommerceFromDataLayer();
    trackProduct("view_item", product);
  },
  trackAddToCart: (product: AnalyticsProduct) => {
    clearEcommerceFromDataLayer();
    trackProduct("add_to_cart", product);
  },
  trackShare: () => {
    gtagEvent("share");
  },
  trackPlaceBidPdp: () => {
    gtagEvent("place_bid_pdp");
  },
  trackReadMoreBidPdp: () => {
    gtagEvent("readmore_bid_pdp");
  },
  trackPlaceBidSuccessPdp: (
    bidMethod: AnalyticsQuickBidMethod,
    product: AnalyticsProduct
  ) => {
    gtagEvent("place_bid_success_pdp", {
      bid_method: bidMethod,
      product_item_id: product.id,
    });
  },
  trackImageZoomPdp: (imageNumber: number) => {
    gtagEvent("image_zoom_pdp", { image_number: imageNumber });
  },
  trackImageBrowsePdp: () => {
    gtagEvent("image_browse_pdp");
  },
  trackLaunchedArPdp: () => {
    gtagEvent("launched_ar_pdp");
  },
  trackContactSellerPdp: () => {
    gtagEvent("contact_seller_pdp");
  },
  trackContactSellerMessageSentPdp: () => {
    gtagEvent("contact_seller_message_sent_pdp");
  },
  trackReadMoreBuyerProtectionPdp: () => {
    gtagEvent("readmore_buyerprotection_pdp");
  },
  trackReadMoreDeliveryOptionsPdp: () => {
    gtagEvent("readmore_deliveryoptions_pdp");
  },
  trackReadMoreParcelPdp: () => {
    gtagEvent("readmore_parcel_pdp");
  },
  trackReadMorePickupPdp: () => {
    gtagEvent("readmore_pickup_pdp");
  },
  trackReadMoreCourierPdp: () => {
    gtagEvent("readmore_courier_pdp");
  },
  trackReadMoreCuratedPdp: () => {
    gtagEvent("readmore_curated_pdp");
  },
  trackReadMoreBrengerPdp: () => {
    gtagEvent("readmore_brenger_pdp");
  },
  trackReadMoreReviewsPdp: () => {
    gtagEvent("readmore_reviews_pdp");
  },
  trackSimilarItemPdp: () => {
    gtagEvent("similar_item_pdp");
  },
  trackShowSimilarItemPdp: () => {
    gtagEvent("show_similar_item_pdp");
  },
  trackShowMoreSimilarItemPdp: () => {
    gtagEvent("show_more_similar_item_pdp");
  },
  trackShowSimilarItemPlp: () => {
    gtagEvent("show_similar_item_plp");
  },
  trackSeeMoreClickPdp: () => {
    gtagEvent("pdp_see_more");
  },
  trackClickedUspCard: () => {
    gtagEvent("brand_promise");
  },
  trackClickedBrandPromiseBlock: () => {
    gtagEvent("brand_promise");
  },
  trackViewCart: (products: AnalyticsProduct[]) => {
    clearEcommerceFromDataLayer();
    trackMultipleProducts("view_cart", products);
  },
  trackViewCartOverview: (products: AnalyticsProduct[]) => {
    clearEcommerceFromDataLayer();
    trackMultipleProducts("view_cart_overview", products);
  },
  trackBeingCheckout: (products: AnalyticsProduct[]) => {
    clearEcommerceFromDataLayer();
    trackMultipleProducts("begin_checkout", products);
  },
  trackAddPaymentInfo: (
    products: AnalyticsProduct[],
    paymentType: PaymentMethod
  ) => {
    clearEcommerceFromDataLayer();
    gtagEvent("add_payment_info", {
      items: products.map(productToAnalyticsItem),
      currency: products[0]?.auction?.buy_now_price?.currency ?? Currency.Eur,
      value: products.reduce((sum, product) => {
        sum += product.auction?.buy_now_price?.amount ?? 0;
        return sum;
      }, 0),
      payment_type: paymentType,
    });
  },
  trackAddShippingInfo: () => {
    clearEcommerceFromDataLayer();
    gtagEvent("add_shipping_info");
  },
  trackClickedPickupCheckout: (product: AnalyticsProduct) => {
    trackProduct("clicked_pickup_checkout", product);
  },
  trackClickedExternalBrengerCheckout: (product: AnalyticsProduct) => {
    trackProduct("clicked_brenger_checkout", product);
  },
  trackClickedPostalCheckout: (product: AnalyticsProduct) => {
    trackProduct("clicked_postal_checkout", product);
  },
  trackClickedServiceCheckout: (
    product: AnalyticsProduct,
    serviceName: string
  ) => {
    trackProduct("clicked_service_checkout", product, {
      service_name: serviceName,
    });
  },
  trackPurchase: (orderGroup: AnalyticsOrderGroup) => {
    const products = orderGroup.orders?.reduce<
      (AnalyticsProduct & { order_state?: OrderState; services?: string[] })[]
    >((products, order) => {
      if (order?.product) {
        products.push({
          ...productToAnalyticsItem(order.product),
          order_state: order.state,
          services: order.buyer_protection_incl_vat
            ? ["buyer_protection"]
            : undefined,
        });
      }

      return products;
    }, []);

    if (isNil(products) || products.length === 0) {
      return;
    }
    clearEcommerceFromDataLayer();
    gtagEvent("purchase", {
      coupon: orderGroup.couponCode,
      payment_method: orderGroup.paymentMethod,
      transaction_id: orderGroup.id,
      shipping: orderGroup.totals?.shipping?.amount,
      tax: orderGroup.totals?.tax?.amount,
      items: products,
      currency: products[0]?.auction?.buy_now_price?.currency ?? Currency.Eur,
      value: orderGroup.totals?.subtotal?.amount,
    });
    gtagEvent("awin.dl.ready", {
      transactionTotal: orderGroup.totals?.subtotal?.amount,
      transactionCurrency: Currency.Eur,
      transactionID: orderGroup.id,
      transactionPromoCode: orderGroup.couponCode,
    });
  },
  trackHpSellNow: () => {
    gtagEvent("hp_sell_now");
  },
  trackHpCategoryNavigation: (navigationItem: string) => {
    gtagEvent("hp_category_navigation", { navigation_item: navigationItem });
  },
  trackHpBrandNavigation: (navigationItem: string) => {
    gtagEvent("hp_brand_navigation", { navigation_item: navigationItem });
  },
  trackHpCollectionNavigation: (navigationItem: string) => {
    gtagEvent("hp_collection_navigation", { navigation_item: navigationItem });
  },
  trackHpNewsletterSubscription: () => {
    gtagEvent("hp_newsletter_subscription");
  },
  trackHpReadAllAbout: (articleName: string) => {
    gtagEvent("hp_read_all_about", { article_name: articleName });
  },
  trackHpSocialMediaLinks: (platform: string) => {
    gtagEvent("hp_social_media_links", { platform });
  },
  trackHpMarketingBanner: () => {
    gtagEvent("hp_marketing_banner");
  },
  trackHpNewIn: () => {
    gtagEvent("hp_new_in");
  },
  trackHpForYou: () => {
    gtagEvent("hp_for_you");
  },
  trackHpFavorites: () => {
    gtagEvent("hp_favourites");
  },
  trackBoostAd: () => {
    gtagEvent("boost_ad");
  },
  trackInviteFriend: () => {
    gtagEvent("invite_friend");
  },
  trackSignUp: () => {
    gtagEvent("sign_up", { event_source: getSignupEventSource() });
  },
  trackAdCreationCompleted: () => {
    gtagEvent("ad_creation_completed");
  },
  trackAdCreationClickedPlaceAd: () => {
    gtagEvent("ad_creation_clicked_placead");
  },
  trackAdCreationStep: (step: number) => {
    gtagEvent(`ad_creation_step${step}`);
  },
  trackPageView: (
    pageUrl: string,
    pageTitle: string,
    language: string,
    me?: AnalyticsMe,
    logInStatus?: AnalyticsLogInStatus
  ) => {
    const merchant = me?.merchants?.[0];

    gtagEvent("pageview_custom", {
      page_url: pageUrl,
      page_title: pageTitle,
      language,
      cm_id: merchant?.id,
      cm_em: merchant?.email,
      cm_emh: merchant?.email ? sha256(merchant?.email) : undefined,
      cm_buys: merchant?.number_of_buys,
      cm_bids: merchant?.number_of_bids,
      cm_sells: merchant?.number_of_sells,
      cm_ads: merchant?.number_of_ads,
      cm_favorites: merchant?.number_of_favorites,
      cm_site_status: logInStatus,
    });
  },
  trackLoginStart: () => {
    gtagEvent("login_start");
  },
  trackSignUpStart: () => {
    gtagEvent("sign_up_start", { event_source: getSignupEventSource() });
  },
  trackSignUpProfileCompleted: () => {
    gtagEvent("sign_up_profile_completed", {
      event_source: getSignupEventSource(),
    });
  },
  trackAddToCartChat: () => {
    gtagEvent("add_to_cart_chat");
  },
  trackEmptyCartOverview: () => {
    gtagEvent("empty_cart_overview");
  },
  trackPlaceBidChat: () => {
    gtagEvent("place_bid_chat");
  },
  trackPayNowChat: () => {
    gtagEvent("paynow_chat");
  },
  trackMessageSentChat: () => {
    gtagEvent("message_sent_chat");
  },
  trackExperimentExposure: (
    experimentName: string,
    experimentGroupName: string
  ) => {
    gtagEvent("experiment_exposure", {
      experiment_name: experimentName,
      experiment_group_name: experimentGroupName,
    });
  },
  trackAnnouncementBar: () => {
    gtagEvent("announcement_bar");
  },
  trackSignUpBlock: (feature: string) => {
    gtagEvent("sign_up_block", { feature });
  },
  trackCalculateShipping: () => {
    gtagEvent("calculate_shipping");
  },
  trackCalculateShippingSuccess: () => {
    gtagEvent("calculate_shipping_success");
  },
  trackCreateAdClickAdsTable: () => {
    gtagEvent("ud_ads_header_sell_now");
  },
  trackCreateAdClickNoAds: () => {
    gtagEvent("ud_ads_details_sell_now");
  },
  trackClickedAppInstall: (device: Device, eventSource?: string) => {
    gtagEvent("clicked_app_install", {
      app_type: device,
      event_source: eventSource ?? getSignupEventSource(false),
    });
  },
  trackChatSellNow: (value: ChatMemberRole) => {
    gtagEvent("chat_sell_now", { value });
  },
  trackClickAppInstallBanner: () => {
    gtagEvent("click_app_install_banner", {
      app_type: getAppType(),
    });
  },
  trackShowedAppInstallBanner: () => {
    gtagEvent("showed_app_install_banner", {
      app_type: getAppType(),
    });
  },
  trackClosedAppInstallBanner: () => {
    gtagEvent("closed_app_install_banner", {
      app_type: getAppType(),
    });
  },
};
