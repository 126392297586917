import {
  AnalyticsMe,
  AnalyticsProvider,
} from "@/common/contracts/analytics.contracts";
import { BRAZE_API_KEY, BRAZE_SDK_ENDPOINT } from "@/config";

const brazeStatus = {
  loading: false,
  initialized: false,
};

const initializeBraze = async () => {
  if (
    !brazeStatus.loading &&
    !brazeStatus.initialized &&
    BRAZE_API_KEY &&
    BRAZE_SDK_ENDPOINT
  ) {
    brazeStatus.loading = true;

    try {
      const {
        initialize,
        openSession,
        automaticallyShowInAppMessages,
        requestPushPermission,
        changeUser,
      } = await import("@braze/web-sdk");

      brazeStatus.initialized = initialize(BRAZE_API_KEY, {
        baseUrl: BRAZE_SDK_ENDPOINT,
        enableLogging: true,
      });

      return {
        openSession,
        automaticallyShowInAppMessages,
        requestPushPermission,
        changeUser,
      };
    } finally {
      brazeStatus.loading = false;
    }
  }
};

export const brazeProvider: AnalyticsProvider = {
  identify: async (me: AnalyticsMe) => {
    try {
      const methods = await initializeBraze();

      methods?.openSession();
      methods?.changeUser(me.id);
      methods?.automaticallyShowInAppMessages();
      methods?.requestPushPermission();
    } catch (ex) {
      console.error((ex as Error).message);
    }
  },
};
