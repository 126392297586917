import { IconProps } from "@/common/contracts/icon.contracts";

const Germany = ({ className }: IconProps) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3139_23904)">
      <rect width="16" height="12" fill="#272727" />
      <rect y="4" width="16" height="4" fill="#E31D1C" />
      <rect y="8" width="16" height="4" fill="#FFD018" />
    </g>
    <defs>
      <clipPath id="clip0_3139_23904">
        <rect width="16" height="12" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Germany;
