import { IconProps } from "@/common/contracts/icon.contracts";

const Switzerland = ({ className }: IconProps) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="2" width="12" height="12" fill="#DC251C" />
    <rect x="7" y="2" width="2" height="8" fill="#F5F8FB" />
    <rect
      x="12"
      y="5"
      width="2"
      height="8"
      transform="rotate(90 12 5)"
      fill="#F5F8FB"
    />
  </svg>
);

export default Switzerland;
