import {
  cloneElement,
  ComponentProps,
  forwardRef,
  PropsWithChildren,
  Ref,
  MouseEvent,
} from "react";
import { cva, VariantProps } from "class-variance-authority";

import { Loading } from "@/common/components/icons/Loading";
import { cn } from "@/common/utils/css.utils";

type ButtonPropsBase<TElement = HTMLButtonElement | HTMLAnchorElement> = {
  ref?: Ref<
    TElement extends HTMLButtonElement ? HTMLButtonElement : HTMLAnchorElement
  >;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  elementRef?: Ref<TElement>;
  disabled?: boolean;
} & Omit<
  ComponentProps<TElement extends HTMLButtonElement ? "button" : "a">,
  "disabled" | "ref"
> &
  Omit<VariantProps<typeof ButtonStyle>, "sizeIconOnly">;

const ButtonStyle = cva(
  "relative rounded-[6px] font-title text-black focus:outline-none",
  {
    variants: {
      color: {
        brand: "",
        attention: "",
        curious: "",
        ghost: "",
      },
      appearance: {
        solid: "border font-bold",
        outlined: "border font-bold",
        minimal: "underline",
      },
      size: {
        small: "px-4 py-1.5 text-sm",
        medium: "px-4 py-1.5 text-base",
        large: "px-6 py-2.5 text-base",
        extraLarge: "px-6 py-4 text-base",
      },
      sizeIconOnly: {
        small: "p-1.5",
        medium: "p-2",
        large: "p-2.5",
        extraLarge: "p-3.5",
      },
      round: {
        true: "rounded-full",
        false: "",
      },
      fill: {
        true: "w-full",
        false: "",
      },
      loading: {
        true: "cursor-not-allowed",
        false: "",
      },
      disabled: {
        true: "cursor-not-allowed",
        false: "",
      },
    },
    compoundVariants: [
      /** Brand  */
      {
        color: "brand",
        appearance: "solid",
        loading: false,
        className:
          "border-brand-500 bg-brand-500 text-white hover:border-brand-400 hover:bg-brand-400 active:border-brand-600 active:bg-brand-600",
      },
      {
        color: "brand",
        appearance: "solid",
        loading: true,
        className: "bg-brand-500",
      },
      {
        color: "brand",
        appearance: "outlined",
        loading: false,
        className:
          "border-brand-500 text-brand-500 hover:border-brand-400 hover:text-brand-400 active:border-brand-600 active:text-brand-600",
      },
      {
        color: "brand",
        appearance: "outlined",
        loading: true,
        className: "border-brand-500",
      },
      {
        color: "brand",
        appearance: "minimal",
        loading: false,
        className: "text-brand-500 hover:text-brand-400 active:text-brand-600",
      },
      /** Attention */
      {
        color: "attention",
        appearance: "solid",
        loading: false,
        className:
          "border-attention-500 bg-attention-500 text-white hover:border-attention-400 hover:bg-attention-400 active:border-attention-600 active:bg-attention-600",
      },
      {
        color: "attention",
        appearance: "solid",
        loading: true,
        className: "bg-attention-500",
      },
      {
        color: "attention",
        appearance: "outlined",
        loading: false,
        className:
          "border-attention-500 text-attention-500 hover:border-attention-400 hover:text-attention-400 active:border-attention-600 active:text-attention-600",
      },
      {
        color: "attention",
        appearance: "outlined",
        loading: true,
        className: "border-attention-500",
      },
      {
        color: "attention",
        appearance: "minimal",
        loading: false,
        className:
          "text-attention-500 hover:text-attention-400 active:text-attention-600",
      },
      /** Curious */
      {
        color: "curious",
        appearance: "solid",
        loading: false,
        className:
          "border-curious-500 bg-curious-500 text-white hover:border-curious-400 hover:bg-curious-400 active:border-curious-600 active:bg-curious-600",
      },
      {
        color: "curious",
        appearance: "solid",
        loading: true,
        className: "bg-curious-500",
      },
      {
        color: "curious",
        appearance: "outlined",
        loading: false,
        className:
          "border-curious-500 text-curious-500 hover:border-curious-400 hover:text-curious-400 active:border-curious-600 active:text-curious-600",
      },
      {
        color: "curious",
        appearance: "outlined",
        loading: true,
        className: "border-curious-500",
      },
      {
        color: "curious",
        appearance: "minimal",
        loading: false,
        className:
          "text-curious-500 hover:text-curious-400 active:text-curious-600",
      },
      /** Ghost */
      {
        color: "ghost",
        appearance: "solid",
        loading: false,
        className:
          "text-back border-white bg-white hover:border-grey-50 hover:bg-grey-50 active:border-grey-200 active:bg-grey-200",
      },
      {
        color: "ghost",
        appearance: "solid",
        loading: true,
        className: "bg-white",
      },
      {
        color: "ghost",
        appearance: "outlined",
        loading: false,
        className:
          "hover:border-gey-50 border-white text-white hover:text-grey-50 active:border-grey-200 active:text-grey-200",
      },
      {
        color: "ghost",
        appearance: "outlined",
        loading: true,
        className: "border-ghost-500",
      },
      {
        color: "ghost",
        appearance: "minimal",
        loading: false,
        className: "text-white hover:text-grey-50 active:text-grey-200",
      },
      /** Any "solid" & disabled */
      {
        appearance: "solid",
        disabled: true,
        className:
          "data-[disabled=true]:border-grey-100 data-[disabled=true]:bg-grey-100 data-[disabled=true]:text-grey-500",
      },
      /** Any ("outlined" or "minimal") and disabled */
      {
        appearance: ["outlined", "minimal"],
        disabled: true,
        className:
          "data-[disabled=true]:border-grey-500 data-[disabled=true]:text-grey-500",
      },
      /** Any !disabled */
      {
        disabled: false,
        className: "focus:ring-2 focus:ring-curious-200",
      },
    ],
    defaultVariants: {
      color: "brand",
      appearance: "solid",
      size: "medium",
      round: false,
      fill: false,
      loading: false,
      disabled: false,
    },
  }
);

type TRestButton = Omit<
  ComponentProps<"button">,
  "color" | "className" | "disabled" | "children"
> & { elementRef?: Ref<HTMLButtonElement> };

type TRestAnchor = Omit<
  ComponentProps<"a">,
  "color" | "className" | "children"
> & { elementRef?: Ref<HTMLAnchorElement> };

/**
 * Type-guard that check whether the rest of the props are for the anchor button.
 */
const isAnchorRest = (rest: TRestButton | TRestAnchor): rest is TRestAnchor => {
  return "href" in rest;
};

const ButtonContent = (
  props: PropsWithChildren<
    ButtonPropsBase<HTMLButtonElement> | ButtonPropsBase<HTMLAnchorElement>
  >
) => {
  const {
    color,
    appearance,
    size,
    round,
    fill,
    loading,
    leftIcon,
    rightIcon,
    className,
    disabled,
    children,
    /** Rest of the props, specific to {@link HTMLButtonElement} or {@link HTMLAnchorElement} depending on whether this is "button" or "a". */
    ...rest
  } = props;

  const content = (
    <>
      <div
        className={cn(
          "flex items-center justify-center gap-[6px]",
          loading && "invisible"
        )}
      >
        {leftIcon && cloneElement(leftIcon, { "aria-hidden": true })}
        {typeof children === "string" ? <span>{children}</span> : children}
        {rightIcon && cloneElement(rightIcon, { "aria-hidden": true })}
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loading className="animate-spin text-grey-300" />
        </div>
      )}
    </>
  );

  const buttonStyleProps = {
    color,
    appearance,
    size: children ? size : undefined,
    sizeIconOnly: !children ? size : undefined,
    round,
    fill,
    loading,
    disabled,
  };

  if (isAnchorRest(rest)) {
    const { elementRef, ...anchorRest } = rest;

    return (
      <a
        className={cn(ButtonStyle(buttonStyleProps), className)}
        data-disabled={disabled}
        ref={elementRef}
        {...anchorRest}
      >
        {content}
      </a>
    );
  }

  const { elementRef, ...buttonRest } = rest;

  return (
    <button
      className={cn(ButtonStyle(buttonStyleProps), className)}
      disabled={loading || disabled}
      data-disabled={disabled}
      ref={elementRef}
      {...buttonRest}
    >
      {content}
    </button>
  );
};

const ButtonBase = (
  props: PropsWithChildren<ButtonPropsBase<HTMLButtonElement>>
) => {
  return <ButtonContent {...props} />;
};

ButtonBase.displayName = "Button";

export type ButtonProps = Omit<
  ButtonPropsBase<HTMLButtonElement>,
  "elementRef"
>;

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonPropsBase<HTMLButtonElement>
>((props, ref) => <ButtonBase {...props} elementRef={ref} />);

const AnchorButtonBase = (
  props: PropsWithChildren<ButtonPropsBase<HTMLAnchorElement>>
) => {
  const { loading, disabled, onClick } = props;

  /** Prevent navigating to `href` for {@link AnchorButton} if it is `disabled` or `loading`. */
  const handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    if (loading || disabled) {
      evt.preventDefault();
      return;
    }

    onClick?.(evt);
  };

  return <ButtonContent {...props} onClick={handleClick} />;
};

AnchorButtonBase.displayName = "AnchorButton";

export type AnchorButtonProps = Omit<
  ButtonPropsBase<HTMLAnchorElement>,
  "elementRef"
>;

/**
 * Simple anchor button that renders {@link HTMLLinkElement} but is styled like {@link Button}.
 *
 * Use {@link AnchorButton} to link to the external resources.
 *
 * Use {@link LinkButton} to link to the internal resource within the project - page for example. This component will
 * respect users locale and show the correct language.
 */
export const AnchorButton = forwardRef<
  HTMLAnchorElement,
  ButtonPropsBase<HTMLAnchorElement>
>((props, ref) => <AnchorButtonBase {...props} elementRef={ref} />);
