import { useCallback, useRef, useState } from "react";

type RunOnceFunc = (func: () => void) => void;

type RunOnceWhen = (predicate: () => boolean) => {
  runOnce: RunOnceFunc;
};

type UseRunOnceOptions<TKeyType = "sessionStorage" | "localStorage"> = {
  key: string;
  keyType: TKeyType;
};

type UseRunOnceReturn = {
  when: RunOnceWhen;
  runOnce: RunOnceFunc;
};

/**
 * Hook that executes function on the client only once.
 */
export const useRunOnce = (options?: UseRunOnceOptions): UseRunOnceReturn => {
  // Hooks
  const called = useRef(false);

  // Functions
  const isBrowser = useCallback(() => typeof window !== "undefined", []);

  const optionsHaveKeyOfType = useCallback(
    <TKeyType extends UseRunOnceOptions["keyType"]>(
      keyType: TKeyType,
      options?: UseRunOnceOptions
    ): options is UseRunOnceOptions<TKeyType> => {
      return !!options?.key && options.keyType === keyType;
    },
    []
  );

  const isCalled = useCallback(() => {
    if (optionsHaveKeyOfType("sessionStorage", options)) {
      return !!sessionStorage.getItem(options.key);
    }

    if (optionsHaveKeyOfType("localStorage", options)) {
      return !!localStorage.getItem(options.key);
    }

    return called.current;
  }, [options, optionsHaveKeyOfType]);

  const updateCalled = useCallback(() => {
    if (optionsHaveKeyOfType("sessionStorage", options)) {
      sessionStorage.setItem(options.key, "true");
      return;
    }

    if (optionsHaveKeyOfType("localStorage", options)) {
      localStorage.setItem(options.key, "true");
      return;
    }

    called.current = true;
  }, [optionsHaveKeyOfType, options]);

  const runOnce = useCallback(
    (predicate: () => boolean) => {
      return (func: () => void) => {
        if (isBrowser() && predicate() && !isCalled()) {
          func();
          updateCalled();
        }
      };
    },
    [isBrowser, isCalled, updateCalled]
  );

  const when = useCallback(
    (predicate: () => boolean) => {
      return { runOnce: runOnce(predicate) };
    },
    [runOnce]
  );

  return { when, runOnce: runOnce(() => isBrowser()) };
};

type UseExecAsyncOptions = {
  onError: (error: Error) => void;
};

type ExecAsyncReturn<TResult> = {
  response?: TResult;
  error?: Error;
};

/**
 * Hook for calling async functions while tracking in-work state, error and response.
 *
 * @param options Options to pass.
 */
export const useExecAsync = (options?: UseExecAsyncOptions) => {
  const [isExecuting, setIsExecuting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const exec = useCallback(
    async <TResult>(
      func: () => Promise<TResult>
    ): Promise<ExecAsyncReturn<TResult>> => {
      setIsExecuting(true);
      setError(null);

      try {
        const response = await func();
        return { response };
      } catch (ex) {
        const error = ex as Error;

        setError(error);
        options?.onError(error);

        return { error };
      } finally {
        setIsExecuting(false);
      }
    },
    [options]
  );

  return { exec, isExecuting, error };
};
